import React, { useEffect } from "react"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert, { AlertProps, Color } from "@material-ui/lab/Alert"
import { Typography } from "@material-ui/core"


export interface Props {
  show: boolean,
  message: string | null
  severity: Color,
  showTime?: number | null
  top?: boolean
  onClose?: () => void
  autoHideDuration?: number | null | undefined
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}


export default function StyledSnackbar(props: Props) {
  const [open, setOpen] = React.useState(props.show)

  useEffect(() => {
    if (props.showTime)
      setOpen((props.showTime ?? 0) > 0)
  }, [props.showTime])


  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return
    }

    setOpen(false)
    if (props.onClose) {
      props.onClose()
    }
  }

  return (
    <Snackbar anchorOrigin={{ vertical: props.top ? "top" : "bottom", horizontal: "center" }} open={open}
              autoHideDuration={
                props.autoHideDuration && props.autoHideDuration > 0 ? props.autoHideDuration :
                  (props.autoHideDuration && props.autoHideDuration < 0 ? undefined :
                    (props.severity == "error" ? 15 * 1000 : 6000))}
              onClose={handleClose}>

      <Alert onClose={handleClose} severity={props.severity}>
        <Typography variant={"body1"}>{props.message}</Typography>
      </Alert>
    </Snackbar>
  )
}